<template>
  <div class="container">
    <div class="window window1" v-show="ifShowWindow" v-window="windowParams">
      <div class="title window__header">
          <div class="controll">
                  <div class="close" @click="close()"></div>
                  <div class="min" @click="min()"></div>
                  <div class="full maximize-btn" @click="full()"></div>
          </div>
          <div class="titlebiaoti">预览浮框</div>
      </div>
      <div class="window__body">
        <iframe id="iframeContainer" frameborder="0" scolling="no" :src="urldata"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
function maximizeCb (isMaximize) {
  this.isMaximize = isMaximize
}
export default {
  data () {
    return {
      windowParams: {
        minWidth: 100,
        maxWidth: 1800,
        minHeight: 100,
        maxHeight: 1800,
        customMaximizeHandler: '.maximize-btn',
        maximizeCallback: maximizeCb.bind(this),
        customMoveHandler: '.window__header',
        moveEndCallback: () => {
          this.initIframe()
        }
      },
      ifShowWindow: true,
      isMaximize: false,
      urldata: ''
    }
  },
  created () {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (flag) {
      if (this.$store.state.Url.indexOf('http') !== -1) {
        const Base64 = require('js-base64').Base64
        this.urldata = `${this.$view}onlinePreview?url=${encodeURIComponent(Base64.encode(this.$store.state.Url))}`
      } else {
        const Base64 = require('js-base64').Base64
        this.urldata = `${this.$view}onlinePreview?url=${encodeURIComponent(Base64.encode('this.$Upload' + this.$store.state.Url))}`
      }
    } else {
      // 使用 indexOf() 方法来检查字符串中是否包含 "http"
      if (this.$store.state.Url.indexOf('http') !== -1) {
        this.urldata = this.$store.state.Url
      } else {
        this.urldata = this.$Upload + this.$store.state.Url
      }
    }
    // const Base64 = require('js-base64').Base64
    // this.urldata = `${this.$view}onlinePreview?url=${encodeURIComponent(Base64.encode(this.$store.state.Url))}`
    // this.urldata = encodeURIComponent(Base64.encode(this.$store.state.Url))
    // console.log('https://qcapi.nienyi.com' + this.$store.state.Url)
  },
  mounted () {
    this.initIframe()
  },
  methods: {
    full () {
      this.isMaximize = !this.isMaximize
    },
    min () {
      alert('只是个备胎')
    },
    close () {
      this.$store.state.ConductIs = ''
      this.$store.state.Url = ''
    },
    initIframe () {
      try {
        const iframeContainer = document.getElementById('iframeContainer')
        const deviceWidth = document.getElementsByClassName('window__body')[0].clientWidth
        const deviceHeight = document.getElementsByClassName('window__body')[0].clientHeight
        iframeContainer.style.width = Number(deviceWidth) + 'px'
        iframeContainer.style.height = (Number(deviceHeight) - 46) + 'px'
      } catch (error) {
      }
    }
  }
}
</script>
<style lang="less" scoped>
.window__body{
  width: 100%;
  height: 100%;
}
  .controll {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    div {
      border-radius: 100%;
      height: 14px;
      width: 14px;
      margin-right: 8px;
      cursor: pointer;
    }
    .close {
      background: #fc605c;
    }
    .close:hover {
      background: #cc2c26;
    }
    .min {
      background: #fcbb40;
    }
    .min:hover {
      background: #c28719;
    }
    .full {
      background: #34c648;
    }
    .full:hover {
      background: #1f942e;
    }
    .full-disabled {
      background: #ccc !important;
    }
}
.title {
    display: flex;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    background: rgba(255, 255, 255, 255);
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 0px 0px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    .close {
      img {
        width: 10px;
      }
      margin-left: auto; // 右对齐
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 48px);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
    overflow: auto;
    border-radius: 0px 0px 10px 10px;
  }
  .titlebiaoti{
  flex-grow: 1;
      text-align: center;
      margin-right: 84px;
      font-weight: 500;
      text-shadow: none;
      font-size: 13px;
      cursor: move;
      color: #333;
}
</style>
